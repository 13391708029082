

.table-container {
    overflow-x: auto !important;
    width: auto !important;
}
.table-repeater {
    border-collapse: collapse;
    table-layout: fixed !important;
    min-width: 100%;
    width: auto;
}

    .table-repeater td, .table-repeater th {
        border: 1px solid #ddd;
        padding: 1px 5px 1px 5px;
        font-size: 16px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
       
    }

    .table-repeater td input, .table-repeater td select, .table-repeater td textarea {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: none;
        box-sizing: border-box;
        outline: none;
        min-height: 40px;
    }
.row-error-border {
    border: 2px solid #edcece !important;
    border-radius: 4px;
}
.error-border::before {
    content: '';
    position: absolute;
    inset: 2px; /* Controls how far inside the border appears */
    border: 1px solid red;
    border-radius: 5px;
    pointer-events: none; /* Makes sure it doesn't interfere with text */
}
    .validationError{
        display:block;
    }
.table-repeater th {
    user-select: none;
}
